import React, { useState, useEffect } from 'react';

const AnimatedCounter = ({ endValue, duration = 2000 }) => {
  const [count, setCount] = useState(1);
  
  useEffect(() => {
    let startTime;
    let animationFrame;
    
    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = (currentTime - startTime) / duration;
      
      if (progress < 1) {
        const currentCount = Math.min(
          Math.floor(1 + (endValue - 1) * progress),
          endValue
        );
        setCount(currentCount);
        animationFrame = requestAnimationFrame(animate);
      } else {
        setCount(endValue);
      }
    };
    
    animationFrame = requestAnimationFrame(animate);
    
    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [endValue, duration]);
  
  return (
    <span className="text-base sm:text-lg font-bold inline-flex items-baseline">
      {count}%
    </span>
  );
};

export default AnimatedCounter;