import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';

const AgentViewer = () => {
  const [decodedUrl, setDecodedUrl] = useState('');
  const [parameters, setParameters] = useState({});
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedUrl = searchParams.get('viewer_url');
    const encodedParams = searchParams.get('params');

    if (encodedUrl) {
      try {
        const decoded = atob(encodedUrl);
        setDecodedUrl(decoded);
      } catch (e) {
        console.error('Failed to decode URL:', e);
      }
    }

    if (encodedParams) {
      try {
        const decodedParams = JSON.parse(atob(encodedParams));
        setParameters(decodedParams);
      } catch (e) {
        console.error('Failed to decode parameters:', e);
      }
    }
  }, [location]);
    
    const urlStart = "https://www.browserbase.com/devtools/inspector.html?wss=connect.browserbase.com/"

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      
      <main className="max-w-6xl mx-auto px-4 sm:px-6 py-8">
        {/* Warning Message */}
        {!parameters.agent_id && (
          <div className="mb-8 bg-red-50 rounded-xl p-6 shadow-sm border border-red-100">
            <p className="text-red-600 font-semibold">Invalid parameters provided</p>
          </div>
        )}

        {/* Agent Title and ID Display */}
        {(parameters.agent_title || parameters.agent_id) && (
          <div className="mb-8 bg-blue-50 rounded-xl p-6 shadow-sm border border-blue-100">
            {parameters.agent_title && (
              <h1 className="text-3xl font-bold text-gray-900 mb-2">{parameters.agent_title}</h1>
            )}
            {parameters.agent_id && (
              <p className="text-gray-600">Agent ID: {parameters.agent_id}</p>
            )}
          </div>
        )}

        {/* Parameters Display */}
        <div className="mb-8 bg-gray-50 rounded-xl p-6 shadow-sm border border-gray-100">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Agent Parameters</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Object.entries(parameters)
              .filter(([key]) => !['agent_title', 'agent_id'].includes(key))
              .map(([key, value]) => (
                <div key={key} className="bg-white p-4 rounded-lg shadow-sm">
                  <span className="font-semibold text-gray-700">{key}:</span>
                  <span className="ml-2 text-gray-600">{value}</span>
                </div>
              ))}
          </div>
        </div>

        {/* Website Display */}
        {decodedUrl && (
          <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100">
            <iframe
              src={urlStart+ decodedUrl}
              className="w-full h-[800px]"
              title="Agent Viewer"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        )}

        {!decodedUrl && (
          <div className="text-center py-12">
            <p className="text-gray-600">No URL provided or invalid encoding</p>
          </div>
        )}
      </main>
    </div>
  );
};

export default AgentViewer;
