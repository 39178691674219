'use client'

import React from 'react';
import Navigation from './Navigation';

const TechnicalDeepDive = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Navigation - same as main page */}
      
      <Navigation/>

      {/* Add Technical Deep Dive Content */}
      <div className="max-w-7xl mx-auto px-8 py-12">
        <h1 className="text-4xl font-bold mb-8">Technical Deep Dive</h1>
        
        <div className="prose prose-lg max-w-none">
          <p className="mb-6">
            We leverage a hierarchical agent architecture as our architecture of choice. We have five agents with distinct roles with three core agents progressing the task in an agent loop.
          </p>

          {/* Add the architecture diagram */}
          <div className="my-12 flex flex-col items-center">
            <img 
              src="/Agent-Kura-Architecture.png" 
              alt="Agent Kura Architecture Diagram" 
              className="w-full max-w-4xl rounded-lg shadow-lg"
            />
            <p className="mt-4 text-sm text-gray-600 italic">
              Agent Kura's Architecture
            </p>
          </div>

          <div className="grid gap-8 mt-8">
            {/* Agent Components */}
            <div className="border rounded-lg p-6 bg-gray-50">
              <h2 className="text-2xl font-semibold mb-4">Initial Planner</h2>
              <p>The initial Planner is provided the task and is responsible for coming up with a high-level plan to seed the Agent Loop Planner. 
                 The Initial Planner leverages OpenAI's new o1 model to reason and come up with the best possible plan.</p>
            </div>

            <div className="border rounded-lg p-6 bg-gray-50">
              <h2 className="text-2xl font-semibold mb-4">Agent Loop Planner</h2>
              <p>On each run of the Agent Loop, the Agent Loop Planner is responsible for:</p>
              <ul className="list-disc ml-6 mt-2">
                <li>Understanding if the task is complete or impossible</li>
                <li>Coming up with the next instruction for the Executor</li>
                <li>Revising the plan as necessary</li>
              </ul>
            </div>

            <div className="border rounded-lg p-6 bg-gray-50">
              <h2 className="text-2xl font-semibold mb-4">Executor</h2>
              <p>The Executor is responsible for taking an instruction from the Agent Loop Planner and executing to the best of its ability until the instruction is complete or the Executor believes the instruction is impossible. The Executor has the following skills:</p>
              <ul className="list-disc ml-6 mt-2">
                <li>Navigate to a URL or Go Back</li>
                <li>Read data from the current page</li>
                <li>Take action on the screen (Click, Scroll, Type)</li>
              </ul>
            </div>

            <div className="border rounded-lg p-6 bg-gray-50">
              <h2 className="text-2xl font-semibold mb-4">Agent Loop Critic</h2>
              <p>The critic is responsible for understanding if the Executor did what the Agent Loop Planner asked of the Executor and to report these findings back to the Agent Loop Planner. For complicated user interfaces such as Date Pickers, the Critic is crucial for grounding the Agent Loop Planner back into reality.</p>
            </div>

            <div className="border rounded-lg p-6 bg-gray-50">
              <h2 className="text-2xl font-semibold mb-4">Final Critic</h2>
              <p>Once the Agent Loop Planner declares the task is complete, we have a Final Critic assess the full trajectory of the task and the final answer to understand if the task was properly completed. If not, the Final Critic will provide feedback to the Agent Loop Planner on why the task is not yet complete and kick off another loop.</p>
            </div>
          </div>

          <div className="mt-8 p-6 bg-gray-50 rounded-lg">
            <h3 className="text-2xl font-semibold mb-4">Note on model usage</h3>
            <p>The current results were obtained using OpenAI's GPT-4o model for all agents besides the Initial Planner. Its worth noting that Claude's new release dramatically improves vision which may lead to an even greater performance for agent Kura.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalDeepDive; 