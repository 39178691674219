'use client'

import React from 'react';
import Navigation from './Navigation';

const Benchmarks = () => {
  const sections = [
    {
      title: "WebVoyager Benchmark",
      id: "webvoyager",
      subsections: [
        { title: "Benchmark Breakdown", id: "breakdown" }
      ]
    },
    {
      title: "Anthropic's Computer Use Comparison",
      id: "computer-use",
    },
    {
      title: "Benchmark Adjustments",
      id: "adjustments",
      subsections: [
        { title: "Removed Tasks", id: "removed-tasks" },
        { title: "Special Considerations", id: "special-considerations" },
      ],
    },
  ];

  React.useEffect(() => {
    document.documentElement.style.scrollPadding = '7rem 0 0 0';
    return () => {
      document.documentElement.style.scrollPadding = '0';
    };
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation - same as main page */}
     
      <Navigation/>

      {/* Updated Benchmarks Content with Side Navigation */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:flex lg:gap-12">
          {/* Left Navigation */}
          <div className="hidden lg:block w-64 flex-shrink-0">
            <div className="sticky top-24">
              <nav className="space-y-1">
                {sections.map((section) => (
                  <div key={section.id}>
                    <a
                      href={`#${section.id}`}
                      className="block px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded-md"
                    >
                      {section.title}
                    </a>
                    {section.subsections && (
                      <div className="ml-4 space-y-1">
                        {section.subsections.map((subsection) => (
                          <a
                            key={subsection.id}
                            href={`#${subsection.id}`}
                            className="block px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 rounded-md"
                          >
                            {subsection.title}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </div>

          {/* Mobile Navigation */}
          <div className="lg:hidden mb-8">
            <select 
              onChange={(e) => {
                const element = document.getElementById(e.target.value);
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
              className="w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500"
            >
              {sections.map((section) => (
                <React.Fragment key={section.id}>
                  <option value={section.id}>{section.title}</option>
                  {section.subsections?.map((subsection) => (
                    <option key={subsection.id} value={subsection.id}>
                      — {subsection.title}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </select>
          </div>

          {/* Main Content */}
          <div className="flex-1">
            <h1 className="text-3xl sm:text-4xl font-bold mb-8">Benchmarks</h1>
            <div className="prose prose-lg max-w-none">
              {/* WebVoyager Benchmark Section */}
              <div id="webvoyager" className="my-12">
                <h2 className="text-3xl font-semibold mb-6">
                  <a 
                    href="https://github.com/MinorJerry/WebVoyager"
                    className="hover:text-red-500 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WebVoyager Benchmark
                  </a>
                </h2>

                <div className="bg-gray-50 rounded-lg p-4 sm:p-8">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-2xl mx-auto">
                    <div className="text-center">
                      <div className="text-4xl font-bold text-red-500">87%</div>
                      <div className="mt-4 font-semibold -ml-3">Kura</div>
                      <div className="text-sm text-gray-600 mt-1">New State of the Art</div>
                    </div>
                    
                    <div className="text-center">
                      <a 
                        href="https://arxiv.org/abs/2407.13032"
                        className="hover:text-red-500 transition-colors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="text-4xl font-bold text-gray-700">73%</div>
                        <div className="mt-4 font-semibold">Agent E</div>
                        <div className="text-sm text-gray-600 mt-1">Previous State of the Art</div>
                      </a>
                    </div>
                    
                    <div className="text-center">
                      <a 
                        href="https://arxiv.org/abs/2401.13919"
                        className="hover:text-red-500 transition-colors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="text-4xl font-bold text-gray-700">57%</div>
                        <div className="mt-4 font-semibold">WebVoyager</div>
                        <div className="text-sm text-gray-600 mt-1">Introduced Benchmark</div>
                      </a>
                    </div>
                  </div>
                </div>

                <div id="breakdown" className="mt-16">
                  <h3 className="text-2xl font-semibold mb-6">Benchmark Breakdown</h3>
                  <div className="overflow-x-auto -mx-4 sm:mx-0">
                    <div className="inline-block min-w-full align-middle">
                      <table className="min-w-full border-collapse border border-gray-200">
                        <thead>
                          <tr className="bg-gray-50">
                                                    <th className="border border-gray-200 px-6 py-4 text-left text-sm font-semibold text-gray-900">Website</th>
                                                    <th className="border border-gray-200 px-6 py-4 text-left text-sm font-semibold text-gray-900">Agent Kura</th>
                                                    <th className="border border-gray-200 px-6 py-4 text-left text-sm font-semibold text-gray-900">Agent-E</th>

                            <th className="border border-gray-200 px-6 py-4 text-left text-sm font-semibold text-gray-900">WebVoyager</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[
                            { site: 'Overall', kura: '87.0%', agentE: '73.1%', webVoyager: '57.1%', isKuraBetter: true },
                            { site: 'Allrecipes', kura: '97.5%', agentE: '71.1%', webVoyager: '51.1%', isKuraBetter: true },
                            { site: 'Amazon', kura: '92.5%', agentE: '70.7%', webVoyager: '52.9%', isKuraBetter: true },
                            { site: 'Apple', kura: '87.2%', agentE: '74.4%', webVoyager: '62.8%', isKuraBetter: true },
                            { site: 'Arxiv', kura: '86.0%', agentE: '62.8%', webVoyager: '52.0%', isKuraBetter: true },
                            { site: 'BBC News', kura: '83.3%', agentE: '73.8%', webVoyager: '60.3%', isKuraBetter: true },
                            { site: 'Booking.com', kura: '84.6%', agentE: '27.3%', webVoyager: '32.6%', isKuraBetter: true },
                            { site: 'Coursera', kura: '87.5%', agentE: '85.7%', webVoyager: '57.9%', isKuraBetter: true },
                            { site: 'Cambridge Dictionary', kura: '90.7%', agentE: '81.4%', webVoyager: '71.3%', isKuraBetter: true },
                            { site: 'ESPN', kura: '85.0%', agentE: '77.3%', webVoyager: '47.0%', isKuraBetter: true },
                            { site: 'Google Flights', kura: '89.7%', agentE: '35.7%', webVoyager: '51.6%', isKuraBetter: true },
                            { site: 'Google Map', kura: '86.8%', agentE: '87.8%', webVoyager: '64.3%', isKuraBetter: false },
                            { site: 'Google Search', kura: '92.8%', agentE: '90.7%', webVoyager: '77.5%', isKuraBetter: true },
                            { site: 'Github', kura: '87.5%', agentE: '82.9%', webVoyager: '59.3%', isKuraBetter: true },
                            { site: 'HuggingFace', kura: '77.1%', agentE: '81.0%', webVoyager: '55.8%', isKuraBetter: false },
                            { site: 'WolframAlpha', kura: '76.1%', agentE: '95.7%', webVoyager: '60.9%', isKuraBetter: false }
                          ].map((row, index) => (
                            <tr key={row.site} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                              <td className="border border-gray-200 px-6 py-4 text-sm text-gray-900 font-medium">
                                {row.site}
                                  </td> 
                                  <td className="border border-gray-200 px-6 py-4 text-sm">
                                <span className={`font-semibold ${row.isKuraBetter ? 'text-green-600' : 'text-red-600'}`}>
                                  {row.kura}
                                </span>
                                  </td>
                             
                              <td className="border border-gray-200 px-6 py-4 text-sm text-gray-900">
                                {row.agentE}
                              </td>
                              
                                  <td className="border border-gray-200 px-6 py-4 text-sm text-gray-900">
                                {row.webVoyager}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  
                  <p className="mt-6 text-gray-600">
                    Performance measured across various websites in WebVoyager shows Kura consistently outperforming other agents,
                    with particularly strong results on e-commerce and content-rich sites.
                  </p>
                </div>
              </div>

              {/* Claude's Computer Use Comparison Section */}
              <div id="computer-use" className="my-16">
                <h2 className="text-3xl font-semibold mb-6">Anthropic's Computer Use Comparison</h2>
                
                <div className="prose prose-lg max-w-none">
                  <p className="mb-6">
                    In order to compare our Agent against <a 
                      href="https://github.com/anthropics/anthropic-quickstarts/tree/main/computer-use-demo"
                      className="text-red-500 hover:text-red-600 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Anthropic's Computer Use demo</a>, we randomly sample <a 
                      href="https://github.com/darrenhwang1/agent-kura-benchmarks/blob/main/WebVoyager_data_computer_use_tasks.jsonl"
                      className="text-red-500 hover:text-red-600 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >50 tasks</a> without replacement from the pool of 602 WebVoyager tasks to compare against.
                  </p>

                  {/* Comparison Stats */}
                  <div className="my-8 bg-gray-50 rounded-lg p-4 sm:p-8">
                                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                                      <div className="text-center">
                        <div className="text-4xl font-bold text-red-500">90%</div>
                        <div className="mt-2 font-semibold">Agent Kura Success Rate</div>
                      </div>
                    
                      <div className="text-center">
                        <div className="text-4xl font-bold text-gray-700">56%</div>
                        <div className="mt-2 font-semibold">Computer Use Success Rate</div>
                                      </div>
                                     
                    </div>
                  </div>

                  <p className="mb-6">
                    The majority of failures observed in Computer Use can be broken down into two cases:
                  </p>

                  {/* Failure Cases */}
                  <div className="grid gap-6 my-8">
                    <div className="border rounded-lg p-6 bg-gray-50">
                      <h3 className="text-xl font-semibold mb-3">Lacking vision/context on the rest of the page</h3>
                      <p>
                        We observed that Computer Use became stuck because it only had the screenshot of a specific 
                        area of the current webpage, and based on this screenshot, the agent could not progress forward.
                      </p>
                      <div className="mt-3 text-gray-600">
                        <p>Examples:</p>
                        <ul className="list-disc ml-6">
                          <li>Creating the Cat Plot task on Wolfram Alpha</li>
                          <li>Setting multiple filters on Coursera's course search page</li>
                        </ul>
                      </div>
                    </div>

                    <div className="border rounded-lg p-6 bg-gray-50">
                      <h3 className="text-xl font-semibold mb-3">Missing a key requirement</h3>
                      <p>
                      We also observed that Computer use would often miss key requirements of the task such as responding back with results that had a minimum number of reviews or ratings. We believe these failures are a combination of the lack of current precision of vision compared to text as well as the lack of a critic.
                                          </p>
                                   
                                          
                                      </div>
                                      <p>
                                              While Agent Kura performed well on flight booking tasks, Computer Use’ ability to navigate calendars with precise coordinates without the need to parse and understand HTML was impressive. We believe there's a significant opportunity to advance Agent Kura with Computer Use’ vision and coordinate capabilities in combination with Agent Kura’s HTML/Text understanding.
                                          </p>
                  </div>
                </div>
              </div>

              {/* Benchmark Adjustments Section */}
              <div id="adjustments" className="my-16">
                <h2 className="text-3xl font-semibold mb-6">Benchmark Adjustments</h2>
                
                <div className="prose prose-lg max-w-none">
                  <p className="mb-6">
                    Although WebVoyager benchmarks offer the ability to benchmark our agent in an actual web environment, 
                    the downside is that some tasks are no longer possible as either the website user interface or data has changed. 
                    Below we detail changes we've made to the benchmarks prior to evaluation.
                                  </p>
                                  
                                  <p>
                        The full dataset of tasks tested can be found in our <a 
                          href="https://github.com/darrenhwang1/agent-kura-benchmarks/tree/main"
                          className="text-red-500 hover:text-red-600 underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >benchmark repository</a>.
                      </p>

                  <h3 id="removed-tasks" className="text-2xl font-semibold mt-8 mb-4">
                    <a 
                      href="https://github.com/darrenhwang1/agent-kura-benchmarks/blob/main/WebVoyager_data_removed_tasks.jsonl"
                      className="text-gray-900 hover:text-red-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Removed Tasks
                    </a>
                  </h3>
                  <p className="mb-4">
                    While the original WebVoyager Benchmark contained 643 tasks, we benchmarked on 602 tasks, 
                    removing 41 tasks. Removal of these 41 tasks fell into one of the four following categories:
                  </p>

                  <div className="space-y-6">
                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-2">Tasks impossible due to website data changes or user interface changes (29 tasks)</h4>
                      <p className="mb-2">Examples:</p>
                      <ul className="list-disc ml-6 space-y-2">
                        <li>BBC has recently removed the link to the World News section making it impossible to navigate to that section from the website.</li>
                        <li>There exists no Chocolate Chip Recipe with a 5-star rating on AllRecipes.com</li>
                      </ul>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-2">Tasks requiring making a booking or reservation (8 tasks)</h4>
                      <p className="mb-2">Example:</p>
                      <ul className="list-disc ml-6 space-y-2">
                        <li>Book a round-trip flight from San Francisco to Berlin, departing on March 5, 2025, and returning on March 12, 2025, and find the option with the shortest total travel time.</li>
                      </ul>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold">Tasks requiring access tokens or authentication (2 tasks)</h4>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold">Tasks requiring browser Print functionality not supported by Playwright (2 tasks)</h4>
                    </div>
                  </div>
                                  

                  <h3 id="special-considerations" className="text-2xl font-semibold mt-12 mb-4">Special Considerations</h3>
                  
                  <div className="space-y-6">
                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-2">Github Rate Limiting</h4>
                      <p>
                        During our benchmarks, Github pushed an update to dramatically rate-limit search queries for any non logged in user. 
                        For any Github task that required repository search capabilities, we logged in with a newly created account before running the task.
                      </p>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-2">Captchas</h4>
                      <p>
                        We encountered Captchas during Amazon tasks and Cambridge Dictionary translation tasks. 
                        To our surprise, Agent Kura was able to solve Amazon Captchas on its own. However, 
                        Agent Kura cannot currently solve Cambridge Dictionary's Captchas. As the WebVoyager datasets 
                        were created to omit websites with Captchas, we manually solved the Cambridge Dictionary 
                        captchas for Agent Kura in the two translation tasks.
                      </p>
                    </div>

                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-2">Date Updates</h4>
                      <p>
                        For the Google Flights and Booking.com tasks, most reservation dates referenced 
                        in tasks are in the past. For any task with a reservation date in the past, we shifted 
                        the date forward by exactly a year.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benchmarks; 