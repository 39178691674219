import React, { useState, useRef } from 'react';

const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [previousVolume, setPreviousVolume] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const togglePlayPause = (e) => {
    e.stopPropagation();
    
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleVolumeChange = (e) => {
    e.stopPropagation();
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    setPreviousVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const toggleMute = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      if (volume > 0) {
        setVolume(0);
        videoRef.current.volume = 0;
      } else {
        setVolume(previousVolume);
        videoRef.current.volume = previousVolume;
      }
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const handleSeek = (e) => {
    e.stopPropagation();
    const newTime = parseFloat(e.target.value);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;  // Reset to beginning
    }
  };

  return (
    <div 
      className="mt-16 relative w-full shadow-2xl rounded-2xl overflow-hidden"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="relative w-full" style={{ paddingBottom: '55.36%' }}>
        <div 
          ref={containerRef}
          className="absolute inset-0"
          onClick={togglePlayPause}
        >
          <video
            ref={videoRef}
            className="absolute top-1/2 left-1/2 w-full h-full -translate-x-1/2 -translate-y-1/2"
            playsInline
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            onEnded={handleVideoEnded}
          >
            <source src="https://kura-demo-videos.s3.us-west-2.amazonaws.com/Kura_Salesforce_demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          {/* Controls overlay */}
          <div 
            className={`absolute inset-0 w-full h-full flex items-center justify-center
              ${isPlaying ? 'bg-transparent' : 'bg-black/20'}
              transition-all duration-200 
              ${isPlaying ? 'opacity-0 hover:opacity-100' : 'opacity-100'}`}
          >
            {/* Scrubber */}
            <div 
              className={`absolute bottom-4 left-4 right-48 flex items-center
                transition-opacity duration-200 bg-black/40 rounded-lg p-2
                ${(isHovering || !isPlaying) ? 'opacity-100' : 'opacity-0'}`}
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSeek}
                className="w-full h-2 rounded-lg appearance-none cursor-pointer 
                  bg-white/20
                  [&::-webkit-slider-runnable-track]:rounded-lg
                  [&::-webkit-slider-thumb]:appearance-none 
                  [&::-webkit-slider-thumb]:w-4 
                  [&::-webkit-slider-thumb]:h-4 
                  [&::-webkit-slider-thumb]:rounded-full 
                  [&::-webkit-slider-thumb]:bg-white
                  [&::-webkit-slider-thumb]:shadow-md
                  [&::-webkit-slider-thumb]:border-2
                  [&::-webkit-slider-thumb]:border-blue-500
                  [&::-webkit-slider-thumb]:relative
                  [&::-webkit-slider-thumb]:z-10"
                style={{
                  background: `linear-gradient(to right, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) ${(currentTime/duration)*100}%, rgba(255,255,255,0.2) ${(currentTime/duration)*100}%, rgba(255,255,255,0.2) 100%)`
                }}
              />
            </div>

            {/* Center play/pause button */}
            <div className={`w-20 h-20 rounded-full ${isPlaying ? 'bg-black/20' : 'bg-black/30'} flex items-center justify-center shadow-xl`}>
              <svg 
                className="w-12 h-12 text-white drop-shadow-lg" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                {isPlaying ? (
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                ) : (
                  <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                )}
              </svg>
            </div>

            {/* Volume controls */}
            <div 
              className={`absolute bottom-4 right-4 flex items-center space-x-2 
                transition-opacity duration-200 bg-black/40 rounded-lg py-1 px-2
                ${(isHovering || !isPlaying) ? 'opacity-100' : 'opacity-0'}`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Volume slider */}
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="w-20 h-2 bg-white/30 rounded-lg appearance-none cursor-pointer 
                  [&::-webkit-slider-thumb]:appearance-none 
                  [&::-webkit-slider-thumb]:w-4 
                  [&::-webkit-slider-thumb]:h-4 
                  [&::-webkit-slider-thumb]:rounded-full 
                  [&::-webkit-slider-thumb]:bg-white
                  [&::-webkit-slider-thumb]:shadow-md
                  [&::-webkit-slider-thumb]:border-2
                  [&::-webkit-slider-thumb]:border-blue-500"
              />
              {/* Volume icon button */}
              <button
                onClick={toggleMute}
                className="p-2 rounded-full bg-black/30 hover:bg-black/40 transition-colors"
              >
                <svg 
                  className="w-5 h-5 text-white" 
                  fill="currentColor" 
                  viewBox="0 0 20 20"
                >
                  {volume === 0 ? (
                    <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  ) : volume < 0.5 ? (
                    <path fillRule="evenodd" d="M10 3.75v12.5a.75.75 0 01-1.264.546L4.703 13H2.75A.75.75 0 012 12.25v-4.5A.75.75 0 012.75 7h1.953l4.033-3.796A.75.75 0 0110 3.75zm2.363 4.497a.75.75 0 011.06 0 4 4 0 010 5.656.75.75 0 11-1.06-1.06 2.5 2.5 0 000-3.536.75.75 0 010-1.06z" />
                  ) : (
                    <path fillRule="evenodd" d="M10 3.75v12.5a.75.75 0 01-1.264.546L4.703 13H2.75A.75.75 0 012 12.25v-4.5A.75.75 0 012.75 7h1.953l4.033-3.796A.75.75 0 0110 3.75zM12.363 8.247a.75.75 0 011.06 0 4 4 0 010 5.656.75.75 0 11-1.06-1.06 2.5 2.5 0 000-3.536.75.75 0 010-1.06z M14.5 10a6 6 0 00-1.5-4.001.75.75 0 011.06-1.06 7.5 7.5 0 010 10.122.75.75 0 01-1.06-1.06A6 6 0 0014.5 10z" />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;