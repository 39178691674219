import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import KuraLanding from './components/KuraLanding';
import TechnicalDeepDive from './components/TechnicalDeepDive';
import Benchmarks from './components/Benchmarks';
import AgentViewer from './components/AgentViewer';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<KuraLanding />} />
        <Route path="/technical-deep-dive" element={<TechnicalDeepDive />} />
        <Route path="/benchmarks" element={<Benchmarks />} />
        <Route path="/agent_viewer" element={<AgentViewer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;