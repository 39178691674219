import React from 'react';
import { useState } from 'react';
import WaitlistModal from './WaitlistModal';
import VideoPlayer from './VideoPlayer';
import { Users, Heart, Eye, Cpu } from 'lucide-react';
import Navigation from './Navigation';
import AIBenchmarkScale from './AIBenchmarkScale';
import AnimatedCounter from './AnimatedCounter';
import AutomationModal from './AutomationModal';

const FeatureCard = ({ Icon, title, description }) => (
  <div className="flex flex-col items-center p-4 sm:p-8 bg-white rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow h-full">
    <div className="rounded-full bg-red-50 p-4 sm:p-6 mb-4 sm:mb-6">
      <Icon size={32} className="text-red-500 sm:size-48" />
    </div>
    <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3 sm:mb-4">{title}</h3>
    <p className="text-base sm:text-lg text-gray-600 text-center leading-relaxed">{description}</p>
  </div>
);

const CapabilitiesPanel = () => {
  const features = [
    {
      icon: Users,
      title: "Multi Agent System",
      description: "Planner, Executor, Critic in a grounded conversation about each action"
    },
    {
      icon: Heart,
      title: "Self Healing",
      description: "Reflection and Backtracking capabilities for unparalleled reliability"
    },
    {
      icon: Cpu,
      title: "Model Agnostic",
      description: <>Use Gemini, GPT mini or your own small model to <strong>lower cost by up to 90%</strong> with slight impact to accuracy</>
    },
    {
      icon: Eye,
      title: "Multi Modal",
      description: "Use of Computer vision and HTML DOM for improved accuracy"
    }
  ];

  return (
    <div className="mt-12 sm:mt-24 mb-12 sm:mb-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
          {features.map((feature, index) => (
            <div key={index} className="min-h-[300px]">
              <FeatureCard
                Icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const KuraLanding = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutomationModalOpen, setIsAutomationModalOpen] = useState(false);



  const handleAutomationRequest = (e) => {
    e.preventDefault();
    setIsAutomationModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Navigation />

      <div className="flex justify-center mt-6 sm:mt-8">
        <div className="flex items-center space-x-2 text-sm">
          <span className="text-black font-medium">Backed by</span>
          <div className="bg-orange-500 text-white px-1.5 py-0.5 text-base font-semibold">Y</div>
          <span className="text-orange-500 font-medium">Combinator</span>
        </div>
      </div>

      <main className="max-w-6xl mx-auto text-center mt-8 sm:mt-12 px-4 sm:px-6">
        <h1 className="text-4xl sm:text-5xl md:text-8xl font-bold tracking-tight text-gray-900 leading-tight">
          Browser agents 
          <span className="text-gray-900"> just got</span>
          <div className="mt-4">
            <span className="text-red-400">a whole lot better</span>
          </div>
        </h1>

        <div className="mt-4 sm:mt-6">
          <AIBenchmarkScale />
        </div>

        <p className="text-lg sm:text-xl text-gray-600 -mt-16 sm:-mt-16">
          <span className="text-base sm:text-lg">
            Agent Kura scores a breakthrough <AnimatedCounter endValue={87} duration={2000} /> on WebVoyager Benchmark. Outperforms Claude's Computer Use demo by <AnimatedCounter endValue={31} duration={2000} />.
          
          </span>
        </p>

        <div className="mt-8 sm:mt-12">
        <a
                href="https://calendly.com/ronitbasu/kura-intro-call"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs sm:text-base bg-gray-900 text-white px-2 sm:px-5 py-1.5 sm:py-2 rounded-lg font-semibold whitespace-nowrap"
              >
                Schedule a Demo
              </a>

          <VideoPlayer />

          <h2 className="mt-12 sm:mt-16 text-4xl sm:text-6xl font-bold tracking-tight text-gray-900">
            AI Teamwork makes the AI dream work
          </h2>
        </div>

        <CapabilitiesPanel />

        <div className="mt-12 sm:mt-16 mb-16 sm:mb-24">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-6 sm:mb-8">Build your Browser Agents with us</h2>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <button
              onClick={handleAutomationRequest}
              className="bg-gray-100 text-gray-900 px-6 sm:px-8 py-3 sm:py-4 rounded-lg text-base sm:text-lg font-semibold hover:bg-gray-200 transition-colors"
            >
              Enterprise
            </button>
            <a
                href="https://calendly.com/ronitbasu/kura-intro-call"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-red-500 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-lg text-base sm:text-lg font-semibold hover:bg-red-600 transition-colors"
                >
                Schedule a Demo
              </a>
          </div>
        </div>
      </main>

      <WaitlistModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <AutomationModal isOpen={isAutomationModalOpen} onClose={() => setIsAutomationModalOpen(false)} />
    </div>
  );
};

export default KuraLanding;