'use client'
import { useState } from 'react';
import config from '../config';

const MAX_LENGTHS = {
  email: 255,
  company: 255
};

const WaitlistModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    company: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    // Check lengths
    for (const [field, value] of Object.entries(formData)) {
      if (value && value.length > MAX_LENGTHS[field]) {
        setStatus({
          type: 'error',
          message: `${field.charAt(0).toUpperCase() + field.slice(1)} must be less than ${MAX_LENGTHS[field]} characters`
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ type: '', message: '' });

    if (!validateForm()) return;

    setIsLoading(true);
    try {
        const response = await fetch(`${config.apiBaseUrl}/api/waitlist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        
        if (!response.ok) throw new Error('Failed to submit');
        
        setStatus({
          type: 'success',
          message: "Successfully joined the waitlist! We'll be in touch soon."
        });
        
        setTimeout(() => {
          onClose();
          setStatus({ type: '', message: '' });
        }, 4000);
        
    } catch (error) {
        console.error('Error submitting form:', error);
        setStatus({
          type: 'error',
          message: 'Something went wrong. Please try again.'
        });
    } finally {
        setIsLoading(false);
    }
  };

  const StatusMessage = () => {
    if (!status.message) return null;
    
    const bgColor = status.type === 'success' ? 'bg-green-50' : 'bg-red-50';
    const textColor = status.type === 'success' ? 'text-green-800' : 'text-red-800';
    
    return (
      <div className={`${bgColor} ${textColor} p-4 rounded-lg mb-4`}>
        {status.message}
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold text-gray-900">Join the Waitlist</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <StatusMessage />
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Work Email</label>
            <input
              type="email"
              id="email"
              maxLength={MAX_LENGTHS.email}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
            />
          </div>
          
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
              Company (optional)
            </label>
            <input
              type="text"
              id="company"
              maxLength={MAX_LENGTHS.company}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500"
              value={formData.company}
              onChange={(e) => setFormData({...formData, company: e.target.value})}
            />
          </div>
          
          <button
            type="submit"
            disabled={isLoading || status.type === 'success'}
            className={`w-full bg-red-500 text-white px-4 py-2 rounded-lg font-medium 
                ${status.type !== 'success' && !isLoading ? 'hover:bg-red-600' : 'opacity-50 cursor-not-allowed'} 
                transition-colors flex items-center justify-center`}
          >
            {isLoading ? (
                <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Submitting...
                </>
            ) : status.type === 'success' ? (
                'Submitted!'
            ) : (
                'Submit'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default WaitlistModal; 